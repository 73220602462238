<template>
  <div class="account">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          placeholder="账户"
          v-model="searchParam.account"
          label="账户"
        ></v-input>
        <v-input
          placeholder="请输入公司|社区名称"
          v-model="searchParam.companyName"
          label="公司|社区名称"
        ></v-input
        ><v-select
          clearable
          :options="accountOps"
          v-model="searchParam.clientType"
          label="账号类型" /><v-select
          clearable
          :options="isHandleOps"
          v-model="searchParam.auditStatus"
          label="审核状态"
      /></template>

      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="deleteUrl(scope.row.id)" />
        <v-button
          text="审核"
          v-if="scope.row.auditStatus == 1"
          type="text"
          @click="handleUrl(scope.row.id)"
        />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <v-select
          :options="verifyAuditStatus"
          v-model="form.auditStatus"
          label="审核状态"
        />
      </div>

      <div class="item">
        <v-input
          type="textarea"
          placeholder="请输入审核备注"
          v-model="form.auditRemarks"
          :width="250"
          label="审核备注"
        ></v-input>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { delDeveloperUrl, developerUrl, handleDeveloperUrl } from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import {
  verifyAuditStatus,
  isHandleOpsMap,
  accountOps,
  accountOpsMap,
  isHandleOps,
} from "./map";

export default {
  name: "account",
  data() {
    return {
      accountOps,
      isHandleOps,
      isdialog: false,
      verifyAuditStatus,
      id: "",
      searchParam: {
        companyName: "",
        account: "",
        clientType: "",
      },
      form: {
        auditStatus: 2,
        auditRemarks: "",
      },
      communitList: [],
      tableUrl: developerUrl,
      headers: [
        {
          prop: "companyName",
          label: "公司|社区名称",
        },
        {
          prop: "companyBusinessLicensePic",
          label: "公司营业执照",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "account",
          label: "账户",
        },
        {
          prop: "clientType",
          label: "账号类型",
          formatter: (row, prop) => accountOpsMap[row.clientType],
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => isHandleOpsMap[row.auditStatus],
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    cancel() {},
    handleUrl(id) {
      this.id = id;
      this.isdialog = true;
    },
    confirm() {
      this.postVerify();
    },
    postVerify() {
      let params = {
        id: this.id,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
      };
      this.$axios
        .post(`${handleDeveloperUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.isdialog = false;
            this.$refs.list.search();
          } else {
          }
        });
    },
    toAdd() {
      this.$router.push({
        name: "accountForm",
      });
    },
    deleteUrl(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: id,
        };
        this.$axios
          .post(`${delDeveloperUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.account {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 10px 0;
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
